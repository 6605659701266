<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" :exact="true">
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    items() {
      let self = this;
      return this.$route.meta.breadcrumbs.map(e => {
        if (e.text.charAt(0) == ":") {
          return {
            text: self.$route.params[e.text.replace(":", "")]
          };
        }
        return e;
      });
    }
  }
};
</script>
